.ajouter-wrapper {
  padding-left: 45px;
  margin-bottom: 45px;
  margin-top: 84px;
  box-sizing: border-box;
}

.title {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 73px;
}

.column-side {
  width: min(44%, 600px);
  /* background-color: green; */
}

.label {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-1 {
  padding: 18px 12px;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-1::placeholder {
  color: #7c7c7c;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
}

.input-1:focus {
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-2 {
  height: 300px;
  width: min(88%, 1200px);
  text-align: left;
  vertical-align: top;
  box-sizing: border-box;
  padding: 18px 12px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ajouter-button {
  width: 130px;
  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: #187498;
  border: 1px solid #187498;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.ajouter-button:hover {
  color: #187498;
  background-color: white;
  cursor: pointer;
}

.ajouter-button:active {
  transform: scale(0.95);
}

.ajouter-button:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: #fff;
  cursor: not-allowed;
}

.row {
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.input-grp {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
  width: 100%;
}

.input-v {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.dropdown-svg {
  position: absolute;
  right: 17px;
  top: 20px;

  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-p {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.currency {
  position: absolute;
  right: 17px;
  top: 18px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-q {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.quantity-buttons {
  position: absolute;
  right: 0;
  top: 6px;
  color: #cccccc;
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}

.minus-plus-buttons {
  color: #000;
  font-family: Lato;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  height: 38px;
  width: 64px;
  border: none;
  padding: 8px;
}

.minus-plus-buttons:hover {
  cursor: pointer;
}

.error-msg {
  color: #eb5353;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.disclaimer {
  color: #187498;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  width: 20px;
  height: 20px;
  flex-shrink: 0;

  border-radius: 14px;
  border: #187498 2px solid;
  text-align: center;
}

.disclaimer:hover {
  cursor: pointer;
}

.notif-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 25px;
  color: #ada7a7;
  border-radius: 3px;
  background: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.notif-button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.notif-button:active {
  background-color: #d9d9d9;
}

.logout-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 25px;
  color: #ada7a7;
  border-radius: 3px;
  background: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.logout-button:active {
  background-color: #d9d9d9;
}
