.sale-add-wrapper {
  padding-top: 75px;
  padding-left: 50px;
  box-sizing: border-box;
  width: 100%;
}

.sale-item-row {
  width: min(600px, 80%);
  margin-bottom: 20px;
  flex-direction: row;
  display: flex;
  column-gap: 20px;
}

.sale-item-row-medicament {
  padding: 18px 12px;
  box-sizing: border-box;
  /* width: 100%; */
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
