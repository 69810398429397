.mdropdown-wrapper{
  display: flex;
  flex-direction: column;
}

.mdropdown-items-wrapper{
    max-height:300px;
    overflow-y :auto;
    /* position: absolute; */
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0px;
    
}

.mdropdown-item{
    width: 100%;
    background-color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    padding-left: 20px;
    line-height: normal;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);    
}

.mdropdown-item:hover{
    background-color: #5798B1;
    color: #fff;
    cursor: pointer;
}

.mdropdown-item p{
    margin: 0px;
    padding: 16px 0px;
}

