.container {
  margin-right: 159px;
  margin-left: 42px;
}
.chart-wrapper {
  display: flex;
  flex-direction: row;
  padding: 80px;
  align-items: baseline;
  justify-content: space-between;
  gap: 80px;
}

canvas {
  border-radius: 3px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 20px;
}

/* .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 60px;
  gap: 60px;
} */

.grid-element {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;

  height: 205px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.grid-text {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
