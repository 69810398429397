.modal {
  display: flex;

  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 765px;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInAndScale 0.5s ease forwards; /* Apply the enter animation */
}

.modal-content.closing {
  animation: fadeOutAndScale 0.5s ease backwards;
}

@keyframes fadeInAndScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutAndScale {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popup-title {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.title-svg-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.paragraph {
  margin-top: 30px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cancel-button {
  width: 130px;
  justify-content: center;

  text-align: center;
  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: black;
  background-color: #dadada;
  border: 1px solid #dadada;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.cancel-button:hover {
  color: black;
  background-color: white;
}
.cancel-button:active {
  transform: scale(0.95);
}

.confirm-button {
  width: 130px;
  justify-content: center;

  text-align: center;
  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: #eb5353;
  border: 1px solid #eb5353;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.confirm-button:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: #fff;
  cursor: not-allowed;
}
.confirm-button:hover {
  color: #eb5353;
  background-color: white;
}
.confirm-button:active {
  transform: scale(0.95);
}

.confirm-button {
  cursor: pointer;
}

.button-wrapper {
  display: flex;
  justify-content: end;
  padding-top: 40px;
}

.close-icon:hover {
  cursor: pointer;
}

.form-select {
  width: 539px;
  height: 65px;
  transform: rotate(0.021deg);
  flex-shrink: 0;
  color: #7c7c7c;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.name-input {
  margin-top: 10px;
  width: 539px;
  border: #000 1px;
  height: 65px;
  transform: rotate(0.021deg);
  flex-shrink: 0;
  color: #7c7c7c;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/images/ep_arrow-up-bold.jpg);
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.print-only {
  display: none; /* Hide on the screen by default */
}

@media print {
  .print-only {
    display: block;
    /* Display when printing */
  }
}
