.title-style {
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.button:hover {
  cursor: pointer;
}
