.header {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  padding-right: 40px;
  padding-left: 40px;
  justify-content: space-between;

  padding-top: 84px;
  position: relative;
}

.positioned-buttons {
  position: absolute;
  top: 60px; /* Adjust the top value to position the buttons as needed */
  right: 40px; /* Adjust the right value to position the buttons as needed */
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* Remove margin properties to position using top and right */
}

.input-class {
  margin-top: 40px;
  width: 470px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 3px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  background-image: url(/public/images/search-normal.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  padding: 25px;
  outline: none;
  text-indent: 30px;
}

.input-class:focus {
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-class::placeholder {
  color: #ada7a7;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-indent: 20px;
  padding-left: 16px;
}

.title {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delete-button {
  width: 130px;
  justify-content: center;

  text-align: center;
  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: red;
  border: 1px solid red;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.add-button {
  width: 130px;
  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: #187498;
  border: 1px solid #187498;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.print-button {
  width: 130px;
  justify-content: center;

  transition-property: all;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  background-color: #36ae7c;
  border: 1px solid #36ae7c;
  font-weight: 500;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.delete-button:hover {
  color: red;
  background-color: white;
}
.delete-button:active {
  transform: scale(0.95);
}

.add-button:active {
  transform: scale(0.95);
}

.print-button:active {
  transform: scale(0.95);
}

.print-button:hover {
  color: #36ae7c;
  background-color: white;
}

.add-button:hover {
  color: #187498;
  background-color: white;
}

.header-left {
  display: flex;
  gap: 0px 10px;
  align-items: start;
}

.filter-button {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 5px 5px 4px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background-image: url(/public/images/fluent_filter-28-filled.png);
  background-repeat: no-repeat;
  background-position: center;
}

.filter-button:hover {
  cursor: pointer;
}

.notif-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 25px;
  color: #ada7a7;
  border-radius: 3px;
  background: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.notif-button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.notif-button:active {
  background-color: #d9d9d9;
}

.user-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 25px;
  color: #ada7a7;
  border-radius: 3px;
  background: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.user-button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.user-button:active {
  background-color: #d9d9d9;
}

.logout-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 25px;
  color: #ada7a7;
  border-radius: 3px;
  background: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.logout-button:active {
  background-color: #d9d9d9;
}

.ticket-button {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #f45050;
  background-image: url(/public/images/noto-v1_ticket.png);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  transition: background-color 0.3s ease;
}

.ticket-button:hover {
  cursor: pointer;
  background-color: #ff6666;
}

.ticket-button:active {
  background-color: #e63e3e;
}

.wrapper-input-button {
  display: flex;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: baseline;
}

.selected-title {
  color: #187498;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
