.sidebar-wrapper {
  /* update 11 */
  left: 0;
  top: 0;
  width: 260px;
  padding-top: 55px;
  box-sizing: border-box;

  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;
  gap: 60px;
  background-color: white;
  position: sticky;
}

.menu {
  display: flex;
  flex-direction: column;
}

.logo {
  color: #187498;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding-left: 29px; */
}

.logo:hover {
  cursor: pointer;
}

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  background: #187498;
  /* update */
  width: 260px;
  height: 53px;
  flex-shrink: 0;
  gap: 8px;
}
.dropdown-item-wrapper {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  transform: translateY(55px);

  z-index: 10;
  position: absolute;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  /* center */
  padding-left: 10px;
  box-sizing: border-box;
  align-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  background: #fff;
  /* update */
  width: 228px;
  height: 53px;
  flex-shrink: 0;
  gap: 8px;
}

.menu {
  color: #131313;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.menu-item {
  display: flex;
  height: 53px;
  flex-direction: row;
  padding-left: 25px;
  gap: 16px;
  max-height: 53px;
  white-space: nowrap;
  text-overflow: hidden;
  align-items: center;
  text-decoration: none;
  color: #131313;
  opacity: 0.6;
}

.menu-item:hover {
  background-color: aliceblue;
}

.menu-item.active {
  /* update 11  */
  width: auto;
  font-family: "Poppins";
  color: #187498;
  opacity: 1;
  border-left: 4px solid #187498;
  font-weight: 500;
  font-size: 16px;
  height: 53px;
}

.main-content {
  /* flex-grow: 1; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
