.navigation-button {
  cursor: pointer;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background: none;
}

.active-page {
  padding: 12px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 3px;
  background: white;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.inactive-page {
  padding: 12px;
  cursor: pointer;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
}

.pagination {
  margin: 20px 0px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 30px;
}
